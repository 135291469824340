var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2b19f2b2256fec1ed1e30df6f0b8283e2730f280"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/


import {
  captureConsoleIntegration,
  httpClientIntegration
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

const apiUrl = process.env.NEXT_PUBLIC_AKVA_API_HOST;
if (typeof apiUrl !== 'string') {
  throw new Error('apiUrl should be a string');
} else {
  Sentry.init({

    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,


    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      captureConsoleIntegration(),
      httpClientIntegration(),
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        networkDetailAllowUrls: [
          window.location.origin,
          apiUrl,
        ],
        networkRequestHeaders: ["Cache-Control", "Content-Type"],
        networkResponseHeaders: ["Referrer-Policy", "Content-Type"],
      }),
    ]
  });
}
